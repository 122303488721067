<template>
  <v-form
    ref="contactPersonnalInfosForm"
    v-model="valid"
    lazy-validation
  >
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <h3>Informations personnelles</h3>
          <p>Saisir les informations personnelles de votre contact</p>
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <v-row>
            <v-col
              md="6"
              sm="12"
            >
              <v-text-field
                id="lastName"
                v-model="contact.lastName"
                autocomplete="family-name"
                hide-details="auto"
                outlined
                dense
                label="Nom"
              ></v-text-field>
            </v-col>

            <v-col
              md="6"
              sm="12"
            >
              <v-text-field
                id="firstName"
                v-model="contact.firstName"
                autocomplete="given-name"
                hide-details="auto"
                outlined
                dense
                label="Prénom"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                id="lastName"
                v-model="contact.email"
                autocomplete="email"
                :error="mailErrorMsg.length > 0"
                :error-messages="mailErrorMsg"
                :rules="emailRules"
                outlined
                dense
                label="E-mail"
                placeholder="prenom.nom@gmail.com"
                :prepend-inner-icon="icons.mdiMail"
                hide-details="auto"
                @input="mailErrorMsg = ''"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
            >
              <v-text-field
                id="phone"
                v-model="contact.phone"
                autocomplete="tel"
                maxlength="10"
                prefix="+33"
                outlined
                dense
                label="Numéro de téléphone"
                placeholder="0612345678"
                :prepend-inner-icon="icons.mdiPhone"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
            >
              <v-text-field
                id="birthdate"
                v-model="contact.birthdate"
                autocomplete="bday"
                :max="today"
                type="date"
                outlined
                dense
                label="Date de naissance"
                hint="Exemple 12/08/1992"
                :prepend-inner-icon="icons.mdiCalendar"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-divider class="my-2"></v-divider>
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                id="addressLine1"
                v-model="contact.address.line1"
                autocomplete="address-line1"
                outlined
                dense
                label="Adresse"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                id="addressLine2"
                v-model="contact.address.line2"
                autocomplete="address-line2"
                outlined
                dense
                label="Adresse seconde ligne"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              sm="12"
            >
              <v-text-field
                id="addressPostcode"
                v-model="contact.address.postcode"
                autocomplete="postal-code"
                outlined
                dense
                label="Code postal"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              md="8"
              sm="12"
            >
              <v-text-field
                id="addressCity"
                v-model="contact.address.city"
                autocomplete="address-level2"
                outlined
                dense
                label="Ville"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-divider class="my-2"></v-divider>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                id="education"
                v-model="contact.education"
                outlined
                dense
                label="Niveau d'étude"
                :items="['Brevet', 'Baccalauréat', 'Baccalauréat profesionnel', 'Bac +1','Bac +2', 'Bac +3', '> Bac +3' ]"
                placeholder="Bac +5"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                id="diploma"
                v-model="contact.diploma"
                outlined
                dense
                label="Diplôme"
                placeholder="BTS, Master, Bachelor"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <v-autocomplete
                id="professionalStatus"
                v-model="contact.professionalStatus"
                :items="professionalStatusList"
                outlined
                dense
                label="Status professionnel"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="contact.infos"
                label="Ajouter une note"
                outlined
                dense
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="my-3"></v-divider>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <h3>Entreprise</h3>
          <p>Ajouter le contact à une entreprise</p>
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <v-row
            v-if="selectedBusinessContact.name.length > 0"
            class="mt-3"
          >
            <v-col
              cols="8"
            >
              <v-text-field
                id="businessContact"
                v-model="selectedBusinessContact.name"
                disabled
                outlined
                dense
                label="Entreprise"
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-btn
                text
                color="primary"
                @click="selectedBusinessContact.name = ''"
              >
                Modifier
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-else
            class="mt-3"
            cols="12"
            md="8"
          >
            <v-col
              md="8"
              sm="12"
            >
              <BusinessContactAutocomplete @businessContactSelected="businessContactSelected"></BusinessContactAutocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mt-5">
      <v-btn
        :disabled="false"
        color="primary"
        @click="createNewContact"
      >
        Enregistrer
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import BusinessContactAutocomplete from '@/components/BusinessContactAutocomplete.vue'
import {
  mdiCalendar,
  mdiPhone,
  mdiMail,
  mdiPencil,
} from '@mdi/js'

export default {
  components: {
    BusinessContactAutocomplete,
  },
  props: {
    preSelectedBusinessContact: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    icons: {
      mdiCalendar,
      mdiPhone,
      mdiMail,
      mdiPencil,
    },
    today: new Date().toISOString().split('T')[0],
    professionalStatusList: [
      'Apprenti', 'Artisan', 'Artisan', 'Auteur', 'Commerçant', 'Exploitant agricole', 'Fonctionnaire', 'Intérimaire', 'interimaire', 'Intermittent du spectacle', 'Libéral', 'Pigiste', 'Saisonnier', 'Salarié', 'Travailleur indépendant', 'Travailleur intermittent', 'Retraité', 'Auto-entrepreneur', 'Recherche d\'emploi', 'Autre',
    ],
    valid: true,
    emailRules: [
      v => !!v || 'Adresse e-mail requise',
      v => /.+@.+\..+/.test(v) || 'Adresse e-mail invalide',
    ],
    preSelectedBusinessContactName: '',
    selectedBusinessContact: {
      name: '',
      _id: '',
    },
    mailErrorMsg: '',
    businessContact: '',
    contact: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      diploma: '',
      education: '',
      infos: '',
      professionalStatus: '',
      address: {
        line1: '',
        line2: '',
        postcode: '',
        city: '',
      },
      courses: [],
    },

    show: true,
  }),
  mounted() {
    if (this.preSelectedBusinessContact) {
      this.fetchBusinessContactName()
    }
  },
  methods: {
    fetchBusinessContactName() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/business-contact/find-name/${this.preSelectedBusinessContact}`)
        .then(res => {
          if (res.status !== 200) {
            this.$store.commit('snackMe', {
              color: 'error',
              text: 'Impossible de trouver l\'entreprise',
              value: true,
            })

            return
          }
          this.selectedBusinessContact = res.data
          console.log(this.selectedBusinessContact)
        })
        .catch(err => console.log(err))
        .finally()
    },
    businessContactSelected(payload) {
      this.selectedBusinessContact.name = payload.name
      this.selectedBusinessContact._id = payload._id
    },
    createNewContact() {
      if (this.$refs.contactPersonnalInfosForm.validate()) {
        this.$http.post(`${process.env.VUE_APP_API_URL}/contact/new`, this.contact)
          .then(res => {
            if (res.status === 200) {
              if (this.selectedBusinessContact.name.length > 0) {
                this.addContactToBusinessContact(res.data._id)
              }
              this.$emit('contactCreated', res.data)
            }
          })
          .catch(err => {
            console.log(err.response.data)
            if (err.response.data.code === 11000) {
              this.mailErrorMsg = 'Adresse e-mail déjà utilisée'
              this.$store.commit('snackMe', {
                color: 'error',
                text: 'L\'adresse e-mail est déjà utilisée.',
                value: true,
              })
            } else {
              this.$store.commit('snackMe', {
                color: 'error',
                text: 'Une erreure est survenue',
                value: true,
              })
            }
          })
          .finally()
      }
    },
    addContactToBusinessContact(contactId) {
      this.$http.put(`${process.env.VUE_APP_API_URL}/business-contact/add-contacts/${this.selectedBusinessContact._id}`, { contacts: [{ contact: contactId }] })
        .then(res => {
          if (res.status === 200) {
            this.$emit('contactAddedToBusinessContact', this.selectedBusinessContact)
          }
        })
        .catch(err => console.log(err))
        .finally()
    },
  },

}
</script>

<style>

</style>
